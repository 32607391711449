module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon-2.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fff3a08d6a6fe3a5c789f9c7c1920d41"},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"peegh438UdI6h7dqfT5e4I71PBdB210y3","devKey":"peegh438UdI6h7dqfT5e4I71PBdB210y3","trackPage":false,"trackPageImmediately":false,"trackPageOnlyIfReady":true,"trackPageOnRouteUpdate":true,"trackPageOnRouteUpdateDelay":1000,"trackPageWithTitle":false,"delayLoad":true,"delayLoadDelay":10000,"delayLoadUntilActivity":true,"delayLoadUntilActivityAdditionalDelay":13000,"manualLoad":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
